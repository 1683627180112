import React from 'react';
import { Row, Col } from 'reactstrap';
import { MAIL } from "../../url/ui";


// FAQ component
const Faq = ({ id }) => {
    // chunk the list to display in two columns
    const rawFaqs1 = [
        {
            id: 1,
            question: 'Why do we need to screen our clients?',
            answer:
                'If you are in a regulated industry, you have the onus to ensure that you are not dealing with high-risk individuals (money launderers, terrorists, politically exposed people, etc) as your clients. At the time of onboarding the client, you need to search the individuals you are dealing with against the global databases of such sanctioned individuals.',
        },
        {
            id: 3,
            question: 'Can we screen our vendors and employees as well?',
            answer:
                'Yes, provided you have their consent.',
        }
    ];

    const rawFaqs2 = [
        {
            id: 2,
            question: 'Do we need our client’s consent before we screen them?',
            answer: "Yes, as per our terms and conditions, you specifically commit to obtaining consent from the individuals that you are going to screen using our system.",
        },
        {
            id: 4,
            question: 'If the client is a company which is owned by other companies or using nominee shareholders, who do we search for?',
            answer:
                'You need to find ultimate beneficial owners (UBOs) of the company you are dealing with and conduct name search on them. In some countries, they are also called Registrable Controllers.',
        },
    ];

    return (
        <div className="bg-white" id={id}>
            <div className={`container mt-5`}>
                <Row>
                    <Col>

                        <div className="text-center ">
                            <h2 className="font-weight-semibold text-dark text-center p-2">Frequently Asked Questions</h2>
                            <p className="text-muted mt-3">
                                {' '}
                                If you’re new to Compliance Box or looking to revisit your compliance process, <br />this guide will help you learn more about the software and its features.
                                <br />
                            </p>

                            <button type="button" className="btn btn-primary btn-sm mt-2">
                                <i className="mdi mdi-email-outline mr-1"></i> <a className="text-white" href={`${MAIL}`}>Email us your question</a>
                            </button>
                            <button type="button" className="btn btn-info btn-sm mt-2 ml-1">
                                <i className="mdi mdi-twitter mr-1"></i> Send us a tweet
                            </button>
                        </div>
                    </Col>
                </Row>

                <Row className="pt-5 mb-5">
                    <Col lg={5} className="offset-lg-1">
                        {rawFaqs1.map((ques, idx) => {
                            return (
                                <div key={idx}>
                                    <div className="faq-question-q-box">Q.</div>
                                    <h4 className="faq-question" data-wow-delay=".1s">
                                        {ques.question}
                                    </h4>
                                    <p className="faq-answer mb-4">{ques.answer}</p>
                                </div>
                            );
                        })}
                    </Col>
                    <Col lg={5} >
                        {rawFaqs2.map((ques, idx) => {
                            return (
                                <div key={idx}>
                                    <div className="faq-question-q-box">Q.</div>
                                    <h4 className="faq-question" data-wow-delay=".1s">
                                        {ques.question}
                                    </h4>
                                    <p className="faq-answer mb-4">{ques.answer}</p>
                                </div>
                            );
                        })}
                    </Col>
                </Row>
            </div>
        </div>

    );
};

export { Faq };
