function getTranslation(key, value = '', caseStyle = false) {
    return value;
}

function calculateTiming(d) {
    let months = 0, years = 0, days = 0, weeks = 0;
    while (d) {
        if (d >= 365) {
            years++;
            d -= 365;
        } else if (d >= 30) {
            months++;
            d -= 30;
        } else if (d >= 7) {
            weeks++;
            d -= 7;
        } else {
            days++;
            d--;
        }
    };
    return {
        years, months, weeks, days
    };
}

function remainingDaysInReadableFormat(d) {

    if (!d) return d;

    let { years, months, weeks, days } = calculateTiming(d);

    let yearString = (years > 0) ? `${years} year${(years > 1) ? 's' : ''} ` : '';
    let monthString = (months > 0) ? `${months} month${(months > 1) ? 's' : ''} ` : '';
    let weekString = (weeks > 0) ? `${weeks} week${(weeks > 1) ? 's' : ''} ` : '';
    let dayString = (days > 0) ? `${days} day${(days > 1) ? 's' : ''} ` : '';

    return `${yearString}${monthString}${weekString}${dayString}`.trim();

}

function replaceAll(str, mapObj) {
    let re = new RegExp(Object.keys(mapObj).join("|"), "gi");

    return str.replace(re, function (matched) {
        return mapObj[matched.toLowerCase()];
    });
}

export {
    getTranslation,
    remainingDaysInReadableFormat,
    replaceAll
};
