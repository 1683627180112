import React, { Component } from 'react';
import { getTranslation } from '../../utility';

class WhatWeDo extends Component {


    render() {

        return (
            <div className={`container pt-4 pb-4`}>
                <h3 className={`pb-3`}>
                    {`${getTranslation('commons.sentences.whatWeDo', 'WHAT WE DO', 'allCaps')} ?`}
                </h3>
                <h4 className={`font-weight-normal text-justify`}>
                    Dealing with a multiple Individuals and Entities may expose you to the risk of Money Laundering, Terrorist Financing, Fraud, Sanctions Busting. To mitigate these risks and to reduce Financial Crime, the Regulators imposes the measures through legislations, regulations, directions, notices and guidelines, combined with close supervision.
                </h4>
                <h4 className={`font-weight-normal text-justify pt-2`}>
                    KYC/KYB checks, Screening and Due Diligence Procedures are such requirements which ensures that we are not a safe haven for money launderers and Terrorism Financers.
                </h4>
                <h4 className={`font-weight-normal text-justify pt-2`}>
                    However, this can be very complex and costly exercise for the Entities. Fulfillment of these requirements also includes ongoing monitoring  and keeping the information up to date and compliant at any point in time.
                </h4>
                <h4 className={`font-weight-normal text-justify pt-2`}>
                    Compliance Box aims to simplify and streamline the process of customer onbaording and monitoring on an ongoing Basis, with high quality data, smart workflows and latest technology.
                </h4>
            </div>
        )
    }
}

export { WhatWeDo };