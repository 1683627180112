import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import './styles.scss';

import { getTranslation } from '../../utility';
import AccountingImg from '../../assets/images/industries/accounting.png';
import TrustImg from '../../assets/images/industries/trust.png';
import HouseImg from '../../assets/images/industries/house.png';
import CasinoImg from '../../assets/images/industries/casino.png';
import BankImg from '../../assets/images/industries/bank.png';
import RingImg from '../../assets/images/industries/ring.png';

class WhoWeServe extends Component {

    data = [
        {
            name: `Accountants`,
            image: AccountingImg
        },
        {
            name: `Company Secretaries`,
            image: TrustImg
        },
        {
            name: `Solicitors`,
            image: BankImg
        },
        {
            name: `Fund Administrators`,
            image: CasinoImg
        },
        {
            name: `Real Estate Agents`,
            image: HouseImg
        },
        {
            name: `Jewellers`,
            image: RingImg
        }
    ];

    renderContent() {

        let items = [];

        for (let j = 0; j < this.data.length; j++) {

            let { name, image } = this.data[j];

            items.push(
                <Col sm={12} md={4} lg={4} key={j}>
                    <div className={`d-flex flex-column align-items-center`}>
                        <div className={`industry-item`}>
                            <div className={`industry-item-image-wrapper`}>
                                <img src={image} alt={name} width={`70%`}></img>
                            </div>
                            <h4 className={`font-weight-normal`}>
                                {name}
                            </h4>
                        </div>
                    </div>
                </Col>
            );
        }


        return items;
    }

    render() {

        return (
            <div className={`container pt-4 pb-4`}>
                <h3 className={`pb-3 text-center`}>
                    {`${getTranslation('commons.sentences.industriesWeServe', 'SERVING PROFESSIONAL REGULATED SECTORS', 'allCaps')}`}
                </h3>
                <Row className={`pt-3`}>
                    {this.renderContent()}
                </Row>
            </div>
        )
    }
}

export { WhoWeServe };