import { axios } from './axios';
import { Config } from '../config';

const METHOD = {
    GET: 'GET',
    PUT: 'PUT',
    DELETE: 'DELETE',
    POST: 'POST'
};

const APPLICATION_JSON = 'application/json';

export const httpClient = {

    baseUrl: Config.SERVER_URL,

    call: (url, parameters) => {
        const finalUrl = `${httpClient.baseUrl}${url}`;
        const options = {
            ...parameters,
            url: finalUrl
        };

        return axios(options);
    },

    parameters: (method = METHOD.GET, body = {}, headerParams) => {

        const withBody = [METHOD.PUT, METHOD.POST, METHOD.DELETE];

        const params = {
            method,
            headers: {
                'Content-Type': APPLICATION_JSON,
                'Accept': APPLICATION_JSON,
                'Access-Control-Allow-Origin': Config.corsDomainAccess.join("|"),
                withCredentials: true,
                ...headerParams
            }
        };

        if (withBody.indexOf(method) !== -1) {
            params.data = JSON.stringify(body);
        }

        return params;
    },

    get: (url, headerParams) => {
        return httpClient.call(url, httpClient.parameters(METHOD.GET, {}, headerParams));
    },

    post: (url, body = {}, headerParams) => {
        return httpClient.call(url, httpClient.parameters(METHOD.POST, body, headerParams));
    },

    put: (url, body = {}, headerParams) => {
        return httpClient.call(url, httpClient.parameters(METHOD.PUT, body, headerParams));
    },

    delete: (url, body = {}, headerParams) => {
        return httpClient.call(url, httpClient.parameters(METHOD.DELETE, body, headerParams));
    }

};