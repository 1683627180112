import React from 'react';
import { Col, Row } from 'reactstrap';
import { PricingPlans } from '../pricing-plans-horizontal';

// Pricing component
const Pricing = ({ id }) => {


    return (
        <React.Fragment >
            <div className={`container`} id={id}>
                <Row className="justify-content-center">
                    <Col xl={10}>
                        <div className={`d-flex flex-column`}>
                            <h2 className="font-weight-semibold text-dark text-center">Our Plans and Pricing</h2>
                            <p className="text-muted w-50 m-auto text-center ">
                                We have plans and prices that fit your business perfectly. Make your client site a success
                                with our products.
                            </p>
                        </div>

                        <Row className="mt-sm-5 mt-3 mb-3">
                            <PricingPlans />
                        </Row>
                    </Col>
                </Row>
            </div>


        </React.Fragment>
    );
};

export { Pricing };
