import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import { LOGO_URL, MAIL } from '../../url/ui';
import { getAddress } from "../../utility/contact";


const Footer = () => {

	const [address, setAddress] = useState({});
	useEffect(() => {
		async function addressHandler() {
			const address = await getAddress();
			setAddress(address);
		}
		addressHandler();
	}, []);

	let defaultAddress = `#23-08, SBF
	Centre, <br /> 160 Robinson Road, <br />${" "}
	Singapore 068914`;

	if (address?.default) {
		let { line1, line2, line3 } = address.default;
		defaultAddress = `${line1}, <br/> ${line2}, <br/> ${line3}`;
	}

	let addressHtml = `Compliance Box Pte Ltd, <br /> ${defaultAddress}`;

	return (
		<footer className="page-footer font-small">
			<div>
				<div className=" bg-primary text-white px-4">
					<div className="row py-2 d-flex align-items-center">
						<div className="col-md-6 col-lg-5 text-center text-md-left mb-4 mb-md-0">
							<h6>Get connected with us on social networks!</h6>
						</div>

						<div className="col-md-6 col-lg-7 text-center text-md-right">
							<a
								className="fb-ic"
								href={`${process.env.GATSBY_APP_URL}`}
							>
								<i className="mdi mdi-facebook text-white mr-4">
									{" "}
								</i>
							</a>
							<a
								className="tw-ic"
								href={`${process.env.GATSBY_APP_URL}`}
							>
								<i className="mdi mdi-twitter text-white mr-4">
									{" "}
								</i>
							</a>
							<a
								className="gplus-ic"
								href={`${process.env.GATSBY_APP_URL}`}
							>
								<i className="mdi mdi-google-plus text-white mr-4">
									{" "}
								</i>
							</a>
							<a
								className="li-ic"
								href={`${process.env.GATSBY_APP_URL}`}
							>
								<i className="mdi mdi-linkedin text-white mr-4">
									{" "}
								</i>
							</a>
							<a
								className="ins-ic"
								href={`${process.env.GATSBY_APP_URL}`}
							>
								<i className="mdi mdi-instagram text-white">
									{" "}
								</i>
							</a>
						</div>
					</div>
				</div>
			</div>

			<div className="container text-center  mt-3">
				<div className="row">
					<div className="col-md-3 col-lg-3 col-xl-3 mx-auto mb-2">
						<h6 className="text-uppercase font-weight-bold">
							{" "}
							<a href='/'>
								<img src={`${LOGO_URL}`} width='50%' alt="compliancebox" />
							</a>
						</h6>
						<hr className="bg-light mb-4 " />

						<p
							className="font-weight-normal text-muted"
							dangerouslySetInnerHTML={{
								__html: addressHtml
							}}
						/>
					</div>

					<div className="col-md-3 col-lg-3 col-xl-3 mx-auto mb-4">
						<h6 className="text-uppercase font-weight-bold">
							Contact
						</h6>
						<hr className="bg-light mb-4" />

						<div className="row">
							<div className="col-md-12 col-lg-12 col-xl-12 mx-auto mb-md-0 mb-4">

								<a href={`${MAIL}`} className="mt-2 font-weight-normal text-muted">
									admin@compliancebox.co
								</a>
							</div>
						</div>


					</div>

					<div className="col-md-4 col-lg-4 col-xl-4 mx-auto mb-md-0 mb-4">
						<h6 className="text-uppercase font-weight-bold ">
							{"Links"}
						</h6>
						<hr className="bg-light mb-4" />
						<div className="row">
							<div className="col-md-12 col-lg-12 col-xl-12 mx-auto mb-md-0 mb-4">

								<p>
									<Link
										to="/terms"
										className="font-weight-normal text-primary"
									>
										Terms & Conditions
									</Link>
								</p>
								<p>
									<Link
										to="/privacy"
										className="font-weight-normal text-primary"
									>
										Privacy Policy
									</Link>
								</p>
								<p>
									<Link
										to="/security"
										className="font-weight-normal text-primary"
									>
										Security Policy
									</Link>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="footer-copyright text-center py-3 text-muted">
				© {new Date().getFullYear()} Copyright:
				<a href={`${process.env.GATSBY_APP_URL}`} className="text-muted">
					{" "}
					Compliance Box - Compliance Box.co
				</a>
			</div>
		</footer>
	);
};

export { Footer };
