import React, { Fragment } from "react";
import { Header, Footer } from "../index";
import Seo from "../seo/index";
/** works in development without /index but in production we needed to add the /index 
 * Gatsby can create a path that is unreadable in production or development mode and this leads to staticQuery problems.
 * */

export default function Layout({ children }) {
	return (
		<Fragment>
			<div>
				<Seo />
				<Header />
				{children}
				<Footer />
			</div>
		</Fragment>
	);
}
