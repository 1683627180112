
import * as Prod from './config.prod';
import * as Dev from './config.dev';
import * as Localhost from './config.localhost';

let Config;

if (process.env.GATSBY_APP_ENV === "production") {
    Config = { ...Prod };
} else if(process.env.GATSBY_APP_ENV === "development") {
    Config = { ...Dev };
} else {
    Config = { ...Localhost };
}

export { Config };
