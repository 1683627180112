import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import TermsImg from "../../assets/images/svg/terms.inline.svg";
import { replaceAll } from "../../utility";
import { getAddress } from "../../utility/contact";
import { data } from './data';

const Terms = () => {

	const [address, setAddress] = useState({});
	useEffect(() => {
		async function addressHandler() {
			const address = await getAddress();
			setAddress(address);
		}
		addressHandler();
	}, []);

	let addressString = ``;

	if (address?.default) {
		let { line1, line2, line3 } = address.default;
		addressString = `${line1}, ${line2}, ${line3}`;;
	}

	let mapObj = {
		"{{address}}": addressString
	};

	return (
		<>
			<div className={`container mt-5`}>
				<Row>
					<Col xs={12} sm={12} md={6} lg={6} xl={6}>
						<div className={`d-flex flex-column`}>
							<h1 className="font-weight-semibold text-dark mt-4">
								<b>Terms And Conditions</b>
							</h1>
							<p className="font-weight-semibold text-muted text-justify mt-3 pr-5">
								By using the Service, you agree to be bound by
								these Terms and our Privacy Policy. If you’re
								using our Service on behalf of an organization
								or entity (“Organization”), then you are
								agreeing to these Terms on behalf of that
								Organization and you represent and warrant that
								you have the authority to bind the Organization
								to these Terms. In that case, “you” and “your”
								refers to that Organization.
							</p>
						</div>
					</Col>

					<Col xs={12} sm={12} md={6} lg={6} xl={6}>
						<div className={`d-flex flex-column `}>
							<TermsImg />
						</div>
					</Col>
				</Row>
			</div>
			<div className="bg-white">
				<div className={`container mt-5 `}>
					<Row>
						<Col className="text-justify">
							<h3 className=" text-dark pt-4">
								SUBSCRIPTION AGREEMENT{" "}
							</h3>
							{data.map((head, id) => {
								return (
									<React.Fragment key={head.id}>
										<h4 className=" text-dark pt-4"> {head.heading}</h4>

										<p className=" font-weight-semibold text-muted mt-2 " dangerouslySetInnerHTML={{
											__html: replaceAll(head.para, mapObj),
										}} />

									</React.Fragment>

								)

							})}

							<h4 className=" text-dark pt-4">
								Modification of Terms and conditions{" "}
							</h4>
							<p className=" font-weight-semibold text-muted mt-2 mb-5">
								Compliance box reserves the right to modify
								these Terms and Conditions and the same will be
								communicated to subscribers through website and
								email.
							</p>
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};

export { Terms };
