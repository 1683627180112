const data = [
    {
        id: 1,
        heading: "IT SECURITY POLICY & PROCEDURES",
        para:
            "We take the security of your data very seriously at Compliance Box. As transparency is one of the principles on which our company is built, we aim to be as clear and open as we can about the way we handle security.",
    },
    {
        id: 2,
        heading: "Infrastructure Security",
        para:
            'We contract our digital hardware to cloud vendors that adhere to the applicable data regulations and compliances.Our infrastructure runs on data centers provided by Amazon Web Services (AWS), which is <a href="https://aws.amazon.com/compliance/programs/" className="text-dark"> SOC2 and PCI Level 1 certified among others</a> . AWS, as a platform provider, has a number of <a href="https://aws.amazon.com/security/" className="text-dark" > security and privacy focused features, </a> which we leverage wherever applicable. <br /><br /> Our servers run on stable, regularly patched, versions of Amazon Linux with carefully configured security groups, isolated VPC environments with well-defined network segmentation, role-based access control, and advanced web application firewall protection. ',
    },
    {
        id: 3,
        heading: "Physical and Environmental Security",
        para:
            'We do not have in-house data centers. Amazon Web Services (AWS) manages the physical and environmental security of our data centers. Our internal security program covers physical security at our offices. <br /> <br /> For more details, please review 	<a className="text-dark" href="https://aws.amazon.com/compliance/data-center/controls/"> AWS control and security measures.</a>',
    },
    {
        id: 4,
        heading: "Product and Service Security",
        para:
            'We distribute and serve our products and services exclusively over HTTPS and secure WebSockets. All network interactions use TLS with 2048-bit digital signatures and 128-bit AES encryption. Additionally, we use HTTP Strict-Transport-Security to ensure the applications never interact with the servers on an insecure network path. <br /> <br /> For more details, please review 	<a className="text-dark" href="https://aws.amazon.com/compliance/data-center/controls/"> AWS control and security measures.</a>',
    },
    {
        id: 5,
        heading: "Software Security",
        para:
            'Our applications run on the latest stable version of <a className="text-dark" href="https://nodejs.org/en/" > Node.js. </a> Our security team sets architectural guidelines,  code reviews, and deploys every software system that can interface with customer data. <br /> Our developers are trained with specific attention toward security. Our automated and manual code review processes look for any code that could potentially violate security policies.',
    },
    {
        id: 6,
        heading: "Confidentiality",
        para:
            'All customer data is stored in databases on	<a className="text-dark" href="https://www.mongodb.com/cloud/atlas"> MongoDB Atlas </a>, which are configured securely. Data is stored with at least dual redundancy, with daily backups, and is accessible only within the private cloud. <br /> <br /> We place strict controls over our employees’ access to the data you and your users make available via the ComplianceBox services, as more specifically defined in your agreement with ComplianceBox covering the use of the ComplianceBox services <b> (“Customer Data”) </b>. The operation of the ComplianceBox services requires that some employees have access to the systems which store and process Customer Data. For example, in order to diagnose a problem you are having with the ComplianceBox services, we may need to access your Customer Data. These employees are prohibited from using these permissions to view Customer Data unless it is necessary to do so. We have technical controls and policies in place to ensure that any access to Customer Data is logged. <br /> <br /> All of our employees and contract personnel are bound by our policies regarding Customer Data and we treat these issues as matters of the highest importance within our company.',
    },
    {
        id: 7,
        heading: "Personnel practices",
        para:
            "ComplianceBox conducts background checks on all employees before employment and employees receive privacy and security training during onboarding as well as on an ongoing basis. All employees are required to read and sign our comprehensive information security policy covering the security, availability and confidentiality of the ComplianceBox services.",
    },
    {
        id: 8,
        heading: "Attack Prevention and Mitigation",
        para:
            "We maintain intelligent, web application firewalls on our load balancers which, along with the elastic scaling capacity of our compute instances, mitigate attacks at the application layer. <br /> We implement measures to detect and prevent log tampering or interruptions. In case of a customer-reported breach, the CEO, and VP of Engineering are notified automatically and the report is responded to within a few hours as per set policies",
    },
    {
        id: 9,
        heading: "Payment Processing",
        para:
            'We process payments using <a className="text-dark" href="https://www.braintreepayments.com/" > Braintree (A PayPal Service) </a> , which falls into level 3 or 4 of<a className="text-dark" href="https://en.wikipedia.org/wiki/Payment_Card_Industry_Data_Security_Standard" > PCI compliance. </a>',
    },
    {
        id: 10,
        heading: "Source Code Security",
        para:
            "We store source code and configuration files in private GitHub repositories. The security and development teams conduct code reviews and execute a static code analysis tools on every code commit. Reviewers shall check for compliance with our conventions and style, potential bugs, potential performance issues, and that the commit is bounded to only its intended purpose. Security reviews shall be conducted on every code commit to securitysensitive modules. Such modules include those that pertain directly to authentication, authorization, access control, auditing, and encryption.",
    },
    {
        id: 11,
        heading: "User level Security",
        para:
            "Compliance Box system has built in Role Based Access Controls (RBACs). Access to workfiles have to be actively given by appropriate senior roles. Logins are protected by Google Recaptcha",
    },
    {
        id: 12,
        heading: "Availability",
        para:
            "We understand that you rely on the ComplianceBox services to work. We’re committed to making ComplianceBox a highly available service that you can rely on. Our infrastructure runs on systems that are fault-tolerant, for failures of individual servers or even entire data centers. Our operations team tests disaster recovery measures regularly and has a 24-hour on-call team to quickly resolve unexpected incidents.",
    },
    {
        id: 13,
        heading: "Disaster recovery",
        para:
            "Customer Data is stored redundantly in multiple locations in our hosting provider’s data centers to ensure availability. We have well-tested backup and restoration procedures which allow recovery from a major disaster. Customer Data and our source code are backed up every week. The team is alerted in the event of a failure in this system. Backups are fully tested at least every 90 days to confirm that our processes and tools work as expected.Customer Data is stored redundantly in multiple locations in our hosting provider’s data centers to ensure availability. We have well-tested backup and restoration procedures which allow recovery from a major disaster. Customer Data and our source code are backed up every week. The team is alerted in the event of a failure in this system. Backups are fully tested at least every 90 days to confirm that our processes and tools work as expected.",
    },
];

export {data};