import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
function SEO({
	description,
	lang,
	image: metaImage,
	title,
	pathname,
	keywords
}) {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						author
						siteUrl
						keywords
						image,
						siteName
					}
				}
			}
		`
	);
	const metaDescription = description || site.siteMetadata.description;
	const image = metaImage || `${site.siteMetadata.image}`;
	const metaKeywords = keywords || site.siteMetadata.keywords || ["compliance box"];
	const metaTitle = title || site.siteMetadata.title;

	const canonical = pathname || `${site.siteMetadata.siteUrl}${pathname}`;
	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={metaTitle}
			titleTemplate={`%s | ${site.siteMetadata.title}`}
			link={
				canonical
					? [
						{
							rel: "canonical",
							href: canonical,
						},
					]
					: []
			}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					name: "keywords",
					content: metaKeywords
				},
				{
					property: `og:title`,
					content: metaTitle
				},
				{
					property: `og:description`,
					content: metaDescription
				},
				{
					property: `og:type`,
					content: `website`
				},
				{
					property: `og:url`,
					content: canonical
				},
				{
					property: `og:site_name`,
					content: site.siteMetadata.siteName
				},
				{
					property: `og:image`,
					content: image
				},
				{
					name: `twitter:creator`,
					content: site.siteMetadata.author
				},
				{
					name: `twitter:title`,
					content: metaTitle
				},
				{
					name: `twitter:description`,
					content: metaDescription
				},
				{
					name: "twitter:card",
					content: "summary_large_image"
				},
				{
					name: "twitter:site",
					content: canonical
				},
				{
					name: "twitter:image",
					content: image
				},
				{
					name: "twitter:url",
					content: canonical
				}
			]}
		/>
	);
}
SEO.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``,
};
SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string,
	image: PropTypes.shape({
		src: PropTypes.string.isRequired,
		height: PropTypes.number.isRequired,
		width: PropTypes.number.isRequired,
	}),
	pathname: PropTypes.string,
};
export default SEO;
