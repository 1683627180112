import React, { Component, createRef } from 'react';

import ContactImg from '../../assets/images/svg/contact-new.inline.svg';
import { GLOBAL_PAYLOAD_KEYS_MAP } from '../../constants/global-keys';
import { httpClient } from '../../http-client';
import { CONTACT_US_BASE_URL } from '../../url/server';
import { getTranslation } from '../../utility';
import { Button, ReCaptcha } from '../common';

class Contact extends Component {

    _captchaRef = createRef();

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            form: {},
            isError: false,
            message: ''
        };
    }

    async handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const captchaToken = await this._captchaRef.current.getToken();

        if (!captchaToken) {
            return;
        }

        let formData = {};
        for (let [key, value] of data.entries()) {
            formData[key] = value;
        }
        let headers = {};
        headers[GLOBAL_PAYLOAD_KEYS_MAP.CAPTCHA_TOKEN] = captchaToken;
        this.contactUs(formData, headers, function () {
            event.target.reset();
        });
    }

    contactUs(data, headers, callback) {
        let that = this;
        let url = CONTACT_US_BASE_URL;

        this.setState({ loading: true, isError: false, message: '' });

        httpClient.post(url, data, headers)
            .then((response) => {
                that.setState({ loading: false, isError: false, message: 'Message sent successfully!' });
                if (callback) callback();
            })
            .catch((error) => {
                that.setState({ loading: false, isError: true, message: error?.message || 'Something went wrong' });
            })
    }

    render() {
        return (
            <div className={`container`} id={this.props.id}>
                <div className={`d-flex flex-column`}>
                    <h2 className="font-weight-semibold text-dark text-center">
                        Book your free demo</h2>
                    <p className="font-weight-normal text-center">Our team members will walk you through the system. </p>
                </div>

                <div className="row row-grid mt-4">
                    <div className="col-12 col-lg-6 m-auto">

                        <div className={`d-flex pb-5 justify-content-center`}>
                            <ContactImg className="img-fluid" width={`80%`}/>


                        </div>
                        <div className="mb-5">
                            <h4 className="font-weight-semibold text-dark">What you get?</h4>
                            <ul className="text-muted">
                                <li>Create new searches, do a complete background check. </li>
                                <li>Manage problematic accounts before they become a risk.</li>
                                <li>Scan history and reporting.</li>
                                <li>Protection against identity theft.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <form id="requestDemo" onSubmit={(e) => this.handleSubmit(e)}>
                            <div className="col-auto">

                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text p-0 px-3">
                                            <i className="uil uil-user"></i>
                                        </div>
                                    </div>

                                    <input type="text" className="form-control" id="name" name="name" placeholder="Name" required />
                                </div>
                            </div>
                            <div className="col-auto pt-3">
                                <div className="input-group ">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text p-0 px-3">
                                            <i className="uil uil-envelope"></i>
                                        </div>
                                    </div>
                                    <input type="email" className="form-control" id="email" name="email" placeholder="Email" required />
                                </div>
                            </div>
                            <div className="col-auto pt-3">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text p-0 px-3">
                                            <i className="uil uil-building"></i>
                                        </div>
                                    </div>
                                    <input type="text" className="form-control" id="companyName" name="companyName" placeholder="Firm Name" required />
                                </div>
                            </div>
                            <div className="col-auto pt-3">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text p-0 px-3">
                                            <i className="mdi mdi-flag"></i>
                                        </div>
                                    </div>
                                    <input type="text" className="form-control" id="companyCountry" name="companyCountry" placeholder="Country" required />
                                </div>
                            </div>
                            <div className="col-auto pt-3">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text p-0 px-3">
                                            <i className="mdi mdi-phone"></i>
                                        </div>
                                    </div>
                                    <input type="text" className="form-control" id="phone" name="phone" placeholder="Phone" required />
                                </div>
                            </div>
                            <div className="col-auto pt-3">
                                <div className="input-group">

                                    <textarea rows="2" className="form-control" id="remarks" name="remarks" placeholder="Remarks" required />
                                </div>
                            </div>
                            <ReCaptcha ref={this._captchaRef} />
                            {this.state.message ?
                                <p className={`${this.state.isError ? 'text-danger' : 'text-success'} mx-2 py-3 font-weight-bold`}>
                                    {this.state.message}
                                </p> :
                                null}
                            <div className="d-flex justify-content-end pt-2 pb-5 " id="demoSubmitButton">
                                <Button color={`primary`} loading={this.state.loading}>
                                    {`${getTranslation('commons.words.request', 'Request')} ${getTranslation('commons.words.demo', 'Demo')}`}
                                </Button>

                            </div>

                        </form>
                    </div>

                </div>


            </div>
        )
    }
}

export { Contact };