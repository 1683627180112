const data = [
    {
        id: 1,
        para: "The Subscription Agreement (Agreement) is between Compliance box Pte. Ltd (Compliance Box), incorporated in Singapore with registered office at ' {{address}} and The Client registered with Compliance Box as a user (Subscriber) The Agreement governs the Subscriber's use of Compliance box services. The subscriber will receive the Compliance box Services if: The subscriber has been registered by Compliance box as a subscriber; and The subscriber has agreed to this agreement, by clicking on the 'I HAVE READ AND AGREE TO THE TERMS OF THE SUBSCRIPTION AGREEMENT'. This Agreement commences on the date it is agreed to by the subscriber (“Commencement Date”). Compliance Box may amend this Agreement from time to time and will publish those amendments on its Website. The subscriber’s continued use of the Compliance Box Service after such amendments will constitute acceptance of the amendments by the subscriber. This agreement shall be governed by and construed in accordance with and is executed pursuant to the laws of Singapore and the parties hereby agree to submit to the non-exclusive jurisdiction to the Courts of Singapore."
    },
    {
        id: 2,
        heading: "Scope of our Services",
        para: "The Subscriber must use the Compliance Box services in accordance with this agreement. <br /> Compliance Box grants to the subscriber a non-exclusive, non-transferable, revocable licence to access and use the Compliance box Service only for the purposes, and subject to any restrictions, specified in this Agreement.  <br /> The subscriber must use the Compliance box services only for assisting in complying with duties and regulations which apply to the subscriber, performing law enforcement duties, performing statutory role. The subscriber can only allow permitted users to access and use the compliance box service and not allow the third person from using the account. <br /> All information furnished hereunder shall be held in strict confidence and shall never be reproduced, revealed or made accessible in whole or in part, in any manner whatsoever, to any others unless required by law, or unless the subscriber first obtains Compliance box's written consent, except that subscriber may reveal to the subject of the information that Compliance box is a source of information about the subject and refer the subject to Compliance box for the purpose of obtaining a copy of the information Compliance box is providing regarding the subject and reviewing the same. It is expressly understood that the subscriber shall neither request information for use of others, nor permit requests to be made under this Agreement by others. The information may not be used by the subscriber in connection with providing advice or recommendation to others. It is being understood that the information is only for the Subscriber's internal use. <br />The subscriber must not take any action which imposes an unreasonable or disproportionately large load on Compliance box infrastructure."
    },
    {
        id: 3,
        heading: "Term of Agreements",
        para: "The agreement commences of the commencement date and continues unless terminated in accordance with the agreement."
    },
    {
        id: 4,
        heading: "Fees",
        para: "The subscriber must pay fees mentioned on the website to enter into the agreement."
    },
    {
        id: 5,
        heading: "Privacy",
        para: "Each party agrees to comply with all privacy and other data protection laws that apply to it. <br /> If the GDPR is applicable to the subscriber’s processing of personal data using the Compliance box Service the subscriber must notify Compliance box."
    },
    {
        id: 6,
        heading: "Subscriber Warranties",
        para: "The subscriber warrants and undertakes that this agreement creates legally binding obligations of the subscriber"
    },
    {
        id: 7,
        heading: "Limitation of Liability",
        para: "If any term, condition, warranty, guarantee or other provision is implied into to this Agreement or applies to services supplied by Compliance box (whether by legislation, common law or otherwise) and it cannot be excluded, restricted or modified (a “Non-Excludable Term”), but Compliance box is able to limit its liability for a breach of such Non-Excludable Term, then Compliance box’s liability for breach of the Nonexcludable Term is limited to one or more of the following at Compliance box's option, the supplying of the services again or the payment of the cost of having the services supplied again. <br /> Subject to clause 'Indemnity', the subscriber’s liability whether arising in contract, tort (including negligence), statute or otherwise under or in connection with the Compliance box Service or this Agreement is excluded in respect of all Consequential Loss. <br /> Nothing in this Agreement limits liability that cannot be limited under law (including in relation to Non-Excludable Terms)."
    },
    {
        id: 8,
        heading: "Indemnity",
        para: "The subscriber agrees to indemnify Compliance box and its related entities, representatives, partners, directors, agents and employees from and against any and all liabilities, claims, costs, losses, damages and expenses, including reasonable legal fees on a full indemnity basis, suffered or incurred by Compliance box, its related entities, representatives, partners, directors, agents and employees, as a result of a) the use of Compliance box service for a purpose or in a manner other than as permitted by this Agreement or a breach of this Agreement, b) failure of the client to give a notice to Compliance box required for GDPR c) any negligent, unlawful or wilfully wrong act or omission of the client or its related entities, representative, partners, directors, agents or employees, etc."
    },
    {
        id: 9,
        heading: "Terminations",
        para: "This agreement (prepaid package) will be valid till the expiry of credits purchased by the subscriber or one year from the date of purchase of last package."
    },
    {
        id: 10,
        heading: "Confidentiality",
        para: "Each party must keep confidential and not use or disclose any Confidential Information of the other party, except as permitted by this Agreement. <br />However, the obligation of confidence does not apply to confidential information that is: a) required to be disclosed by law b) in the public domain otherwise than as a breach of this agreement or another obligation of confidence. <br /> The obligations of confidentiality survive the termination of this agreement."
    },
    {
        id: 11,
        heading: "Assignment",
        para: "The subscriber may not assign any rights arising out of this Agreement without the prior written consent of Compliance box which consent is not to be unreasonably withheld."
    },
    {
        id: 12,
        heading: "Waiver",
        para: "A party’s failure to insist that the other party perform any obligation under this Agreement is not a waiver of that right: <br /> <br /> (1) to insist the other party perform, or to claim damages for breach of, that obligation; or <br /> (2) to insist the other party perform any other obligation, unless other party provides a waiver in writing."
    },
]

export { data };
