import { Config } from '../config';

function getAssetURL(path) {
    return `${Config.assetsUrl}${path}`;
}

function getPublicAssetURL(path) {
    return `${Config.publicAssetsUrl}${path}`;
}

const IMAGES = {
    PNG: {
        SCREENSHOTS: {
            dashboard: getAssetURL("/screenshots/app/Dashboard.png"),
            cdd: getAssetURL("/screenshots/app/cdd.png"),
            kyc: getAssetURL("/screenshots/app/kyc.png"),
            screening: getAssetURL("/screenshots/app/screening.png"),
            monitoring: getAssetURL("/screenshots/app/monitoring.png")
        }
    }
};

const DATA = {
    JSON: {
        contact: getPublicAssetURL("/data/json/contact.json")
    }
};

const VIDEOS = {
    MP4: {
        demo: getAssetURL("/videos/demo.mp4")
    }
};

export {
    DATA, IMAGES,
    VIDEOS
};

