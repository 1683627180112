import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import './styles.scss';

import { getTranslation } from '../../utility';

class OnTopOfIt extends Component {

    data = [
        {
            name: `Live`,
            header: `Dashboard`,
            icon: `mdi mdi-view-dashboard`
        },
        {
            name: `Built In`,
            header: `Access Controls`,
            icon: `mdi mdi-settings`
        },
        {
            name: `Multiple`,
            header: `Compliances`,
            icon: `mdi mdi-shield-check`
        },
        {
            name: `Sophisticated`,
            header: `Platform`,
            icon: `mdi mdi-monitor-multiple`
        },
        {
            name: `Excellent`,
            header: `Customer Support`,
            icon: `mdi mdi-account-star`
        },
        {
            name: `Hosted On`,
            header: `Secured Cloud`,
            icon: `mdi mdi-cloud-check`
        },
        {
            name: `No set up time`,
            header: `Easy to deploy`,
            icon: `mdi mdi-code-braces`
        },
        {
            name: `Affordable`,
            header: `Pricing`,
            icon: `mdi mdi-currency-usd`
        }
    ];

    renderContent() {

        let items = [];

        for (let j = 0; j < this.data.length; j++) {

            let { name, icon, header } = this.data[j];

            items.push(
                <Col xs={12} sm={12} md={6} lg={3} xl={3} key={j}>
                    <div className="text-center">
                        <div className="card">
                            <div className="card-body">
                                <i className={`${icon} text-primary font-xxx-large`}></i>
                                <h4 className="card-subtitle mb-2 text-center text-muted font-weight-semibold">
                                    {name}
                                </h4>
                                <h3 className="card-title text-center font-weight-semibold">
                                    {header}
                                </h3>
                            </div>
                        </div>
                    </div>
                </Col>
            );
        }


        return items;
    }

    render() {

        return (
            <div className={`container pt-4 pb-4`}>
                <h3 className={`pb-3`}>
                    {`${getTranslation('commons.sentences.onTopOfIt', 'ON THE TOP OF IT', 'allCaps')}`}
                </h3>
                <Row className={`pt-3`}>
                    {this.renderContent()}
                </Row>
            </div>
        )
    }
}

export { OnTopOfIt };