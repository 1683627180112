const SERVER_URL = process.env.GATSBY_APP_SERVER_URL;
const corsDomainAccess = ["https://app.compliancebox.co", "https://api.compliancebox.co", "https://compliancebox.co"];
const reCaptchaSiteKey = process.env.GATSBY_APP_RECAPTCHA_SITE_KEY;
const assetsUrl = "https://assets.compliancebox.co";
const publicAssetsUrl = "https://assets.smartaudit.co";

export {
    SERVER_URL, assetsUrl, corsDomainAccess, publicAssetsUrl, reCaptchaSiteKey
};

