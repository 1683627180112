import React, { Component } from 'react';
import { Col, Row } from "reactstrap";
import './styles.scss';

import { IMAGES } from '../../constants/assets-src';


class HowWeDo extends Component {

    data = [
        {
            header: 'Know your Customer / Business ( KYC / KYB )',
            subHeader: 'Know who you are dealing with ultimately',
            description: "Performing a comprehensive identity verification check reduces risk of fraud, risk of breaking compliance rules, and risk from dealing with dirty money. Once a bad customer passes the initial checks, they are past the gate and can start testing your fraud prevention systems. Compliance box provides you a systematic workflow for performing 'KYC/KYB' procedures with:",
            image: IMAGES.PNG.SCREENSHOTS.kyc,
            content: [
                {
                    name: "Comprehensive Identity Checks"
                },
                {
                    name: "Verifying Ultimate owners for Non-Individual clients"
                },
                {
                    name: "Understanding Customer's Nature of Business activities"
                },
                {
                    name: "Verification with the appropriate supportings"
                },
                {
                    name: "Real time identification of triggers for the need to update a KYC/KYB risk profile"
                }
            ]
        },
        {
            header: 'AML Screening',
            subHeader: 'Integrated with the onboarding workflow',
            description: "With the High Quality database, Screen clients and prospects against :",
            image: IMAGES.PNG.SCREENSHOTS.screening,
            content: [
                {
                    name: "PEP, Sanctions, Regulatory Watchlists, RCA"
                },
                {
                    name: "Minimised False Negatives"
                },
                {
                    name: "Smart Resolution for Matches"
                }
            ]
        },
        {
            header: 'Customer Due Diligence ( CDD / Enhanced CDD )',
            subHeader: 'Customer due diligence simplified for ease of use',
            description: "Compliance box can help you perform the Holistic customer due diligence designed to surface risk related to various factors like Customer Risk, Geographical Risk, Transaction/Service Risk, Industry Risk,  Delivery Channel Risk. The system flow has been simplified for ease of use and follows Risk Based approach for efficiency.",
            image: IMAGES.PNG.SCREENSHOTS.cdd,
            content: [
                {
                    name: "Alerts for risk indicators"
                },
                {
                    name: "Detailed Risk Assessment"
                },
                {
                    name: "CDD Report Generation"
                },
                {
                    name: "Visualise information that would otherwise not be at your fingertips"
                }
            ]
        },
        {
            header: 'Ongoing Monitoring',
            subHeader: 'Keep a track of changing risks',
            description: "The risk profile of your stakeholders has the potential to change over time. The system will perform the ongoing monitoring of the Individuals or entities against the database. It will also highlight if the the checks are due or if there is any trigger for the reassessment of client.",
            image: IMAGES.PNG.SCREENSHOTS.monitoring,
            content: [
                {
                    name: "Be alerted in advance for ID expiry"
                },
                {
                    name: "Continual screening and alert for any Matches"
                }
            ]
        }
    ];

    renderContent() {
        let items = [];

        for (let j = 0; j < this.data.length; j++) {
            let { header, description, image, content, subHeader } = this.data[j];

            let itemContents = [];

            if (content && content.length) {

                for (let k = 0; k < content.length; k++) {
                    let { name } = content[k];

                    itemContents.push(
                        <li key={`${j}-${k}`}>
                            <h5 className={`font-weight-normal text-justify`}>{name}</h5>
                        </li>
                    );
                }
            }

            items.push(
                <Row key={`${j}`} className={`my-5 how-we-do-item`}>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <h4 className={`text-primary text-uppercase`}>{header}</h4>
                        <h5 className={``}>{subHeader}</h5>
                        <h5 className={`font-weight-normal text-justify`}>
                            {description}
                        </h5>
                        {itemContents?.length ? <ul>{itemContents}</ul> : null}
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className={`how-we-do-item-image-wrapper`}>
                            <img src={image} alt={header} width={`100%`} />
                        </div>
                    </Col>
                </Row>
            );
        }

        return items;
    }

    render() {

        return (
            <div className={`container pb-4`} id={this.props.id}>

                {this.renderContent()}
            </div>
        )
    }
}

export { HowWeDo };
