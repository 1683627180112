import React, { Component } from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import './styles.scss';

import { getTranslation, remainingDaysInReadableFormat } from '../../utility';

import { httpClient } from '../../http-client';

import { SCREENING_PRICING_PLANS } from '../../url/server';
import { ADMIN_PAYMENT } from '../../url/ui';

class PricingPlans extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            plans: []
        };
    }

    componentDidMount() {
        this.apiHandlers();
    }

    componentWillUnmount() {
    }

    componentDidUpdate(prevProps) { }

    apiHandlers() {
        this.getPricingPlansHandler();
    }

    getPricingPlansHandler() {
        let that = this;
        let url = SCREENING_PRICING_PLANS;

        this.setState({ loading: true });

        httpClient.get(url)
            .then((response) => {
                that.setState({ loading: false, plans: response.plans });
            })
            .catch((error) => {
                that.setState({ loading: false });
            })
    }

    renderPlans() {

        let { plans } = this.state;

        let content = [];

        let currency = `$`;

        if (plans?.length) {
            plans.forEach((plan, pIndex) => {
                content.push(
                    <div className={`d-flex flex-fill border-bottom p-2 align-items-center`} key={`content-${pIndex}`}>
                        <div className={`d-flex flex-fill w-20`}>
                            {plan?.features?.noOfScans}{` `}{`${getTranslation('commons.words.searches', 'Searches')}`}
                        </div>
                        <div className={`d-flex flex-fill w-20`}>
                            {currency}{plan?.baseAmount?.amount}
                        </div>
                        <div className={`d-flex flex-fill w-20`}>
                            {currency}{plan?.features?.amountPerScan?.amount}
                        </div>
                        <div className={`d-flex flex-fill w-20`}>
                            {remainingDaysInReadableFormat(plan?.basePeriodInDays)}
                        </div>
                        <div className={`d-flex flex-fill w-20 justify-content-end`}>
                            <a href={`${ADMIN_PAYMENT}/${plan._id}`}>
                                <Button outline color={`primary`}>
                                    {`${getTranslation('commons.words.buyNow', 'Buy Now')}`}
                                </Button>
                            </a>
                        </div>
                    </div>
                )
            })
        }

        return content;
    }

    renderLoadingContainer() {
        let loadingRows = 5;
        let columns = [1, 2, 3, 4, 5];
        let rows = [];

        for (let j = 0; j < loadingRows; j++) {
            rows.push(
                <div key={`item-${j}`} className={`d-flex flex-fill`}>
                    {columns.map((column, index) => { return <div key={`column-${index}`} className={`item d-flex flex-fill`}></div> })}
                </div>
            );
        }

        return (
            <div className={`loadingContent d-flex flex-fill flex-column`}>
                {rows}
            </div>
        );
    }

    renderContainer() {

        let { loading } = this.state;

        return (
            <Card>
                <CardBody>
                    <div className={`d-flex flex-fill border-horizontal p-2 font-weight-bold`}>
                        <div className={`d-flex flex-fill w-20`}>
                            {`${getTranslation('commons.words.search', 'Search', 'startCase')} ${getTranslation('commons.words.credit', 'Credit', 'startCase')}`}
                        </div>
                        <div className={`d-flex flex-fill w-20`}>
                            {`${getTranslation('commons.words.Price', 'Price')} (USD)`}
                        </div>
                        <div className={`d-flex flex-fill w-20`}>
                            {`${getTranslation('commons.words.costPerScan', 'Cost Per Scan')}`}
                        </div>
                        <div className={`d-flex flex-fill w-20`}>
                            {`${getTranslation('commons.words.validity', 'Validity')}`}
                        </div>
                        <div className={`d-flex flex-fill w-20`}>

                        </div>
                    </div>
                    <div className={`d-flex flex-fill flex-column`}>
                        {loading ? this.renderLoadingContainer() : this.renderPlans()}
                    </div>
                </CardBody>
            </Card>
        );
    }

    render() {

        return (
            <React.Fragment>
                <div className={`pricingPlans w-100`}>
                    {this.renderContainer()}
                </div>
            </React.Fragment>
        );
    }
}

export { PricingPlans };
