import React from "react";
import { Col, Row } from "reactstrap";
import './styles.scss';

import { SIGNUP } from '../../url/ui';
import { getTranslation } from "../../utility";
import { IMAGES, VIDEOS } from "../../constants/assets-src";

const Hero = () => {
	return (
		<div className={`container mt-5 pt-0 pt-md-1`}>
			<Row className="mt-5 no-gutters">
				<Col xs={12} sm={12} md={5} lg={5} xl={5}>
					<div className={`d-flex flex-column`}>
						<h1 className="font-weight-semibold text-primary custom-header" title="Smart Customer Due Diligence (CDD) Platform">
							Smart Customer Due Diligence (CDD) Platform
						</h1>

						<h5 className="font-weight-normal mt-2">
							<ul className="list-group-flush custom-list">
								<li className="border-0 mb-2">
									Identification (KYC)
								</li>
								<li className="border-0 mb-2">
									Screening (AML)
								</li>
								<li className="border-0 mb-2">
									Risk Assessment
								</li>
								<li className="border-0 mb-2">
									Enhanced CDD
								</li>
								<li className="border-0">
									Ongoing Monitoring
								</li>
							</ul>
						</h5>

						<div className={`d-flex flex-fill my-4`}>
							<div className={`d-flex`}>
								<a
									href={`${SIGNUP}`}
									className="btn btn-primary"
								>
									{`${getTranslation('commons.words.signUp', 'Sign Up')}`}
								</a>
							</div>
							<div className={`d-flex ml-3`}>
								<a
									href={`#contact`}
									className="btn btn-outline-primary"
								>
									{`${getTranslation('commons.sentences.contactUs', 'Contact Us')}`}
								</a>
							</div>
						</div>
					</div>
				</Col>
				<Col xs={12} sm={12} md={7} lg={7} xl={7} className={`d-flex align-items-center`}>
					<div className={`d-flex`}>
						<video
							controls={false}
							muted
							autoPlay={"autoplay"}
							loop
							width={`100%`}
							height={`100%`}
							poster={IMAGES.PNG.SCREENSHOTS.dashboard}>
							<source src={VIDEOS.MP4.demo} type="video/mp4" />
						</video>
					</div>
				</Col>
			</Row>
		</div>
	);
};

export { Hero };
