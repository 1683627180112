import React, { Component, createRef } from 'react';
import GoogleReCAPTCHA from "react-google-recaptcha";
import { Config } from '../../../config';

class ReCaptcha extends Component {

    reCaptchaRef = createRef();

    async getToken() {
        const captchaToken = await this.reCaptchaRef.current.executeAsync();
        this.reCaptchaRef.current.reset();
        return captchaToken;
    }

    render() {

        return (
            <GoogleReCAPTCHA
                sitekey={Config.reCaptchaSiteKey}
                size="invisible"
                badge={"bottomleft"}
                ref={this.reCaptchaRef}
            />
        )
    }
}
export default ReCaptcha;
