const axios = require('axios');

function setupInterceptors() {

    // Add a request interceptor
    axios.interceptors.request.use(function (config) {
        // Do something before request is sent
        return config;
    }, function (error) {
        // Do something with request error
        return Promise.reject(error);
    });
    // Add a response interceptor
    axios.interceptors.response.use(function (response) {
        // Do something with response data
        let responseData = response.data;

        return responseData;
    }, function (error) {
        // Do something with response error
        let errorData = {};

        if (error.response) {
            errorData = error.response;
        } else if (error.data) {
            errorData = error.data;
        }

        return Promise.reject(errorData);
    });

}

setupInterceptors();

export {
    axios
};