import React, { useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,

} from 'reactstrap';
import { LOGO_URL } from '../../url/ui'

const Header = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);


  return (
    <div className={`container`}>
      <Navbar color="white" light expand="lg" fixed='top' className="shadow-sm mb-5 rounded py-2 px-5 ">
        <NavbarBrand href='/' className='px-5'> <img src={`${LOGO_URL}`} width='20%' alt="compliancebox" /> </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink href="#features">Features</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#pricing">Pricing</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#faq">FAQs</NavLink>
            </NavItem>

          </Nav>

          <NavLink href={`${process.env.GATSBY_APP_URL}`} className="btn btn-primary text-white mx-3">Login</NavLink>

        </Collapse>
      </Navbar>
    </div>
  );

}

export { Header };





