const BASE_URL = `${process.env.GATSBY_APP_URL}`;
const ASSETS_BASE_URL = `${process.env.GATSBY_APP_ASSETS_URL}`;

const IMAGE_URL = ASSETS_BASE_URL + '/logo.png';
const SIGNUP = BASE_URL + '/account/register';
const MAIL = "mailto:admin@compliancebox.co"
const LOGO_URL = ASSETS_BASE_URL + '/logo.png';

const ADMIN_BASE_URL = BASE_URL + '/admin';


const ADMIN_PRICING = ADMIN_BASE_URL + '/pricing';
const ADMIN_PAYMENT = ADMIN_PRICING + '/payment';
const FREE_TRIAL = SIGNUP + '?couponCode=WELCOME';

export {
    ADMIN_PAYMENT,
    IMAGE_URL,
    SIGNUP,
    MAIL,
    LOGO_URL,
    FREE_TRIAL
}