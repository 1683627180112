import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import PrivacyImg from "../../assets/images/svg/privacy.inline.svg";
import { MAIL } from "../../url/ui";
import { replaceAll } from "../../utility";
import { getAddress } from "../../utility/contact";
import { data } from './data';

const Privacy = () => {

	const [address, setAddress] = useState({});
	useEffect(() => {
		async function addressHandler() {
			const address = await getAddress();
			setAddress(address);
		}
		addressHandler();
	}, []);

	let addressString = ``;

	if (address?.default) {
		let { line1, line2, line3 } = address.default;
		addressString = `${line1}, ${line2}, ${line3}`;;
	}

	let mapObj = {
		"{{address}}": addressString
	};

	return (
		<>
			<div className={`container mt-5`}>
				<Row>
					<Col xs={12} sm={12} md={6} lg={6} xl={6}>
						<div className={`d-flex flex-column`}>
							<h1 className="font-weight-semibold text-dark mt-4">
								<b>Privacy Policy</b>
							</h1>
							<p className="font-weight-semibold text-muted text-justify mt-4 pr-5">
								Keeping our clients' data secure is an absolute
								top priority for us. Our goal is to provide a
								secure environment, while also being mindful of
								application performance and the overall user
								experience.
							</p>
						</div>
					</Col>

					<Col xs={12} sm={12} md={6} lg={6} xl={6}>
						<div className={`d-flex `}>
							<PrivacyImg />
						</div>
					</Col>
				</Row>
			</div>
			<div className="bg-white">
				<div className={`container mt-5 `}>
					<Row>
						<Col className="text-justify">
							<h3 className=" text-dark pt-4">
								PRIVACY STATEMENT{" "}
							</h3>

							{data.map((ques, idx) => {
								return (
									<React.Fragment key={ques.id}>
										<h4
											className=" text-dark pt-4"
											data-wow-delay=".1s"
										>
											{ques.question}
										</h4>
										<h5 className=" text-dark">
											{ques.heading}
										</h5>

										<p
											className=" font-weight-semibold text-muted mt-2 "
											dangerouslySetInnerHTML={{
												__html: replaceAll(ques.answer, mapObj),
											}}
										/>
									</React.Fragment>
								);
							})}

							<h4 className=" text-dark pt-4">Contact us</h4>
							<p className=" font-weight-semibold text-muted mt-2 mb-5">
								You can email us at <a href={`${MAIL}`}>admin@compliancebox.co</a>,
								If you need to contact us or have any questions
								on the Privacy Policy.
							</p>
						</Col>
					</Row>
				</div>
			</div>

		</>
	);
};

export { Privacy };
