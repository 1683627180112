import React, { Component } from 'react';
import { Button } from 'reactstrap';


class ButtonWrapper extends Component {

    render() {
        let { color, disabled, loading, children, onClick, block, size, close, className, outline } = this.props;
        return (
            <Button
                disabled={disabled || loading}
                color={color || "primary"}
                onClick={onClick}
                block={block || false}
                outline={outline || false}
                size={size}
                close={close}
                className={className}>
                {loading ?
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                    : null}
                {children}
            </Button>
        )
    }
}
export default ButtonWrapper;
