import { DATA } from '../constants/assets-src';
import { axios } from '../http-client/axios';

async function getAddress() {

    let address = {};

    try {
        const url = DATA.JSON.contact;
        const data = await axios.get(url);
        if (data?.address) {
            address = data.address;
        }
        return address;
    } catch (err) {
        return address;
    }
}

export {
    getAddress
};
