import React from "react";
import { Col, Row } from "reactstrap";
import SecurityImg from "../../assets/images/svg/security.inline.svg";
import {data} from './data';
import { MAIL } from "../../url/ui";


const Security = () => {
	
	return (
		<>
			

			<div className={`container mt-5`}>
				<Row>
					<Col xs={12} sm={12} md={6} lg={6} xl={6}>
						<div className={`d-flex flex-column`}>
							<h1 className="font-weight-semibold text-dark mt-4">
								<b>Security Policy</b>
							</h1>
							<p className="font-weight-semibold text-muted text-justify mt-4 pr-5">
								Keeping our clients' data secure is an absolute
								top priority for us. Our goal is to provide a
								secure environment, while also being mindful of
								application performance and the overall user
								experience.
							</p>
						</div>
					</Col>

					<Col xs={12} sm={12} md={6} lg={6} xl={6}>
						<div className={`d-flex`}>
							<SecurityImg />
						</div>
					</Col>
				</Row>
			</div>
			<div className="bg-white">
				<div className={`container mt-5 `}>
					<Row>
						<Col className="text-justify">
							<h3 className=" text-dark pt-4">
								SECURITY STATEMENT{" "}
							</h3>

							{data.map((head, idx) => {
								return (
									<React.Fragment key={head.id}>
										<h4 className=" text-dark pt-4">
											{head.heading}
										</h4>
										<p
											className=" font-weight-semibold text-muted mt-2 "
											dangerouslySetInnerHTML={{
												__html: head.para,
											}}
										/>
									</React.Fragment>
								);
							})}

							<h4 className=" text-dark pt-4">Contact us</h4>
							<p className=" font-weight-semibold text-muted mt-2 mb-5">
								You can email us at <a href = {`${MAIL}`}>admin@compliancebox.co</a> ,
								If you need to contact us or have any questions
								on the Security Policy.
							</p>
						</Col>
					</Row>
				</div>
			</div>
			
		</>
	);
};

export { Security };
